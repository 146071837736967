<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <ol class="breadcrumb m-0"></ol>
          </div>
          <h4 class="page-title">User Enrollment Detail</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <place-holder v-if="loading"></place-holder>
    <div class="row" v-show="!loading">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-6 custom-border">
                      <div class="table-responsive">
                        <table
                          class="table table-centered table-nowrap table-hover mb-0"
                        >
                          <tbody v-if="!loading">
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Module</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.module.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Employee Type</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.employee_type.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Module Group</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.group.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th style="vertical-align: top !important">
                                <span class="ms-1">Trainer</span>
                              </th>
                              <td>
                                <!-- style="display: block" -->
                                <span
                                  class="ms-1"
                                  v-for="(trainer, index) in trainers"
                                  :key="index"
                                  >{{ trainer.name }}
                                  <span v-if="index + 1 < trainers.length"
                                    >,
                                  </span>
                                </span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">From Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.from_date
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">To Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.to_date
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Enrolled Expired Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.expired_date
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Training Hour</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.module.duration
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Location</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.location
                                }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                    </div>
                    <div class="col-xl-6">
                      <div class="table-responsive">
                        <table
                          class="table table-centered table-nowrap table-hover mb-0"
                        >
                          <tbody v-if="!loading">
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Employee Name</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_user?.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Employee Id</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_user.emp_id
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Position Level</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_user?.position_level?.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Department</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_user?.department?.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">BU/BR/DIV</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_user?.business_unit?.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Approved By</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_detail?.approved_by.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Approved By Email</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_detail?.approved_by.email
                                }}</span>
                              </td>
                            </tr>

                            <tr
                              class="custom-tr"
                              v-if="enroll_detail?.approved_by_hr?.name"
                            >
                              <th>
                                <span class="ms-1">Approved By HR</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_detail?.approved_by_hr?.name
                                }}</span>
                              </td>
                            </tr>
                            <tr
                              class="custom-tr"
                              v-if="enroll_detail?.approved_by_hr?.email"
                            >
                              <th>
                                <span class="ms-1">Approved By HR Email</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_detail?.approved_by_hr?.email
                                }}</span>
                              </td>
                            </tr>

                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Status</span>
                              </th>
                              <!-- style="padding: 0.45rem 0.9rem" -->
                              <td>
                                <div style="width: 195px; padding-left: 6px">
                                  <v-select
                                    label="name"
                                    :clearable="false"
                                    v-model="enroll_detail_status"
                                    disabled
                                    data-bs-toggle="tooltip"
                                    title="You can't change status!"
                                  >
                                  </v-select>
                                </div>
                              </td>
                            </tr>
                            <tr
                              class="custom-tr"
                              v-if="
                                enroll_detail.is_enroll_confirmed == 0 &&
                                enroll_detail.is_approved.status == false
                              "
                            >
                              <th>
                                <span class="ms-1">Remark</span>
                              </th>
                              <!-- style="padding: 0.45rem 0.9rem" -->
                              <td>
                                <div style="width: 195px; padding-left: 6px">
                                  <textarea
                                    name="enroll_remark"
                                    v-model="enroll_detail.remark"
                                    disabled
                                    style="cursor: not-allowed"
                                    class="form-control"
                                    data-bs-toggle="tooltip"
                                    title="Reason for Rejected!"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr
                              class="custom-tr"
                              v-else-if="
                                enroll_detail.is_enroll_confirmed == 1 &&
                                enroll_detail.is_approved.status == false
                              "
                            >
                              <th>
                                <span class="ms-1">Remark</span>
                              </th>
                              <!-- style="padding: 0.45rem 0.9rem" -->
                              <td>
                                <div style="width: 195px; padding-left: 6px">
                                  <textarea
                                    name="enroll_remark"
                                    v-model="enroll_detail.remark"
                                    disabled
                                    style="cursor: not-allowed"
                                    class="form-control"
                                    data-bs-toggle="tooltip"
                                    title="Reason for Rejected!"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr
                              class="custom-tr"
                              v-else-if="
                                enroll_detail.is_enroll_confirmed == 3 &&
                                enroll_detail.is_approved.status == false
                              "
                            >
                              <th>
                                <span class="ms-1">Remark</span>
                              </th>
                              <!-- style="padding: 0.45rem 0.9rem" -->
                              <td>
                                <div style="width: 195px; padding-left: 6px">
                                  <textarea
                                    name="enroll_remark"
                                    v-model="enroll_detail.remark"
                                    disabled
                                    style="cursor: not-allowed"
                                    class="form-control"
                                    data-bs-toggle="tooltip"
                                    title="Reason for Rejected!"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                    </div>
                  </div>
                  <div class="row">
                    <router-link
                      :to="{
                        name: 'enrollment-user-list',
                      }"
                    >
                      <button
                        type="button"
                        class="btn btn-sm btn-blue waves-effect waves-light mb-2 float-end mt-2"
                      >
                        <i class="mdi mdi-arrow-left"></i>
                        Back
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    vSelect,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      monthly_plan: null,
      trainers: "",

      enroll_detail: "",
      enroll_detail_status: "",
      enroll_user: null,

      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  methods: {
    async getEnrollDetail() {
      let enrolled_user_id = null;
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v1/enroll-lists-detail/${this.$route.params.id}`,
          {
            headers: {
              "content-type": "text/plain",
            },
          }
        )
        .then((response) => {
          this.monthly_plan = response.data.data.monthly_plan;

          this.trainers = this.monthly_plan.trainers;

          this.enroll_detail = response.data.data;

          this.enroll_detail_status = this.enroll_detail.is_approved.by;

          // this.enroll_detail_status = this.enroll_detail.is_enroll_confirmed;
          // if (this.enroll_detail_status == 0) {
          //   this.enroll_detail_status = "Rejected";
          // } else if (this.enroll_detail_status == 1) {
          //   this.enroll_detail_status = "Approved";
          // } else if (this.enroll_detail_status == 2) {
          //   this.enroll_detail_status = "Waiting";
          // } else if (this.enroll_detail_status == 3) {
          //   this.enroll_detail_status = "Canceled";
          // }

          enrolled_user_id = this.enroll_detail.enrolled_by.employee_id;
        })
        .catch(() => {
          this.toast.error("Not Found Employees!");
        });
      await axios
        .get(`${this.baseUrl}admin/v1/employee/search/${enrolled_user_id}`)
        .then((response) => {
          this.enroll_user = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
  },
  created() {
    this.getEnrollDetail();
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped>
.custom-tr {
  border-style: hidden !important;
}

.card {
  border: 1px solid #ccc !important;
  margin-bottom: 0px !important;
}

.table > :not(caption) > * > * {
  padding: 0.8rem 0.85rem;
}

.custom-border {
  border-right: solid #ccc !important;
  border-width: thin;
  /* height: 100%; */
  height: auto;
}
</style>
